import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import SEO from "../components/Seo"
import Layout from "../components/Layout"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            id
            title
            slug
            description
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="پکیج رایگان آموزش طراحی سایت"
        description="خیلی ها میپرسیدن چطور میتونن برنامه نویسی یا طراحی سایت رو یاد بگیرن که با توجه به درخواست های بالا، پکیج رایگانی آماده کردم که توش طراحی سایت رو آموزش میدم"
      />

      <div className="blog-wrapper wrapper">
        <div className="container-fluid">
          <div className="wrapper-title">
            <span className="heading-meta">آموزش طراحی سایت رایگان</span>
            <h1>پکیج رایگان آموزش طراحی سایت</h1>
          </div>
          <div className="row">
            {data.allContentfulBlogPost.edges.map(blog => {
              return (
                <div className="col-lg-6 col-md-12" key={blog.node.id}>
                  <Link to={`/blog/${blog.node.slug}`} className="post-item">
                    <h2>{blog.node.title}</h2>
                    <p>{blog.node.description}</p>
                    <span className="details">جزئیات بیشتر</span>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}
